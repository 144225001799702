import React, { useState } from "react"
import { graphql, StaticQuery } from "gatsby"

import PageHeader from "../../pageSections/pageHeader"
import ParagraphText from "../../pageSections/paragraphText"
//import Meta from "../../addon/meta"

const HeadSection = () => {

    let [components, setComponents] = useState([])
	  //let metaTags = []

    const getData = (data) => {
        let pageTitle = ''
        let pageInfo = {}
        if (data.allNodeTemplatePages && data.allNodeTemplatePages.edges) {
            pageInfo = data.allNodeTemplatePages.edges[0]
            pageTitle = pageInfo?.node?.title ? pageInfo.node.title : ''
            const componentsArr = pageInfo?.node?.relationships?.components ? pageInfo.node.relationships.components : []
            setComponents(componentsArr)
            //metaTags = data?.allNodeTemplatePages?.edges?.[0]?.node?.metatags || []
        }
        return pageTitle
    }

    return <StaticQuery
            query={graphql`
            query aboutBoardofMgmtPage {
              allNodeTemplatePages(filter: {path: {alias: {regex: "/board-of-management$/"}}}) {
                edges {
                  node {
                    id
                    title
                    path {
                      alias
                    }
                    metatags: metatag_normalized {
                      attributes {
                        content
                        name
                        property
                      }
                    }
                    metatags_on_page: field_meta_tags{
                      canonical_url
                    }
                    relationships {
                      components: field_component_type {
                        __typename
                        ...ParagraphTitleAndDescription
                        ...ParagraphInset
                        ...ParagraphTabComponentAbtHead
                        ...ParagraphHTMLText
                        ...ParagraphImageComponent
                        ...ParagraphQuote
                        ...ParagraphImageWithTitleAndDescriptionAbtHead
                        ...ParagraphQuote2
                        ...ParagraphImageTextMgmt
                      }
                    }
                  }
                }
              }
            }
            
            fragment ParagraphTitleAndDescription on paragraph__title_and_description {
              id
              field_title
              text: field_description {
                processed
              }
            }
            
            fragment ParagraphImageComponent on paragraph__image_component {
              id
              image: field_image {
                alt
              }
              relationships {
                image: field_image {
                  id
                  uri {
                    value
                    url
                  }
                }
              }
            }
            
            fragment ParagraphHTMLText on paragraph__html_text {
              id
              htmlText: field_text {
                processed
              }
            }
            
            fragment ParagraphInset on paragraph__inset {
              id
              inset: field_inset_text
            }
            
            fragment ParagraphQuote on paragraph__quote {
              id
              quote: field_quotation
            }
            
            fragment ParagraphQuote2 on paragraph__quote_variation_2 {
              id
              pre: field_pre_quote
              quote: field_quotation
              post: field_post_quote
            }
            
            fragment ParagraphImageWithTitleAndDescriptionAbtHead on paragraph__image_with_title_and_description {
              id
              title: field_title
              description: field_content {
                processed
              }
              image: field_image {
                alt
              }
              alignment: field_alignment
              relationships {
                image: field_image {
                  id
                  uri {
                    value
                    url
                  }
                }
              }
            }
            
            fragment ParagraphImageTextMgmt on paragraph__image_text {
              id
              text: field_text_demo {
                processed
              }
              image: field_image_demo {
                alt
              }
              relationships {
                image: field_image_demo {
                  id
                  uri {
                    value
                    url
                  }
                }
              }
            }
            
            fragment ParagraphTabComponentAbtHead on paragraph__tab_components {
              id
              title: field_title
              relationships {
                components: field_components {
                  __typename
                  ...ParagraphInset
                  ...ParagraphHTMLText
                  ...ParagraphImageComponent
                }
              }
            }
            
            `}
            render={data => (
				<>
					<section className="section_bg pt-first-section pb-section">
						<div className="container">
							<div className="section-head mb-0">
								<PageHeader
									title={getData(data)}
								/>
								{
								(components && components[0]) &&
								<ParagraphText
									content={components[0]}
								/>
								}
							</div>
						</div>
					</section>  
				</>
            )}
            />
}

export default HeadSection
